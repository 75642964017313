<!--
  check if promo is true, if true check if its single promo or all if nor show regularCheckout template.
-->
<div #checkoutContainer *ngIf="planOptions.promoType != 'standalone'">
  <div *ngIf="planOptions.productItem.length == 1 else promoAll">
    <div class="promo-checkout" [hidden]="hidePromoCheckout">
      <button class="btn promo-checkout-btn" (click)="onClickFreeTrial(planOptions.productItem[0], 0)"> OK, I'm ready!</button>
    </div>
  </div>
</div>

<!--
  Template for promo = true && promo type = all
-->
<ng-template #promoAll>
  <div *ngIf="planOptions?.active == 'true' else regularCheckout">
    <div class="promo my-5">
      <ng-container *ngFor="let item of planOptions.productItem; let i = index">
        <div class="promo-column promo-column-{{item.active}}" (click)="onClickFreeTrial(item, i)">
          <div class="billing-chip" [ngClass]="{'color-yellow': item.plan == 'Yearly'}">
            <span *ngIf="item.plan == 'Yearly' ">Best Value</span>
          </div>
          <div class="promo-plan">
            <p class="promo-plan-type">{{item.plan}}</p>
            <div>
              <p class="promo-plan-price">{{item.regularPrice}}</p>
              <p class="promo-plan-price-desc">Normal Price</p>
            </div>
          </div>
          <hr/>
          <div>
            <div class="promo-your">
              <span></span>
              <p class="promo-your-price">{{item.price}}</p>
              <p class="promo-your-price-desc pt-2">Your Price</p>
            </div>
            <p class="promo-policy">{{item.policy}}</p>
            <p class="promo-policy" *ngIf="item.trialPolicy">{{item.trialPolicy}}</p>
            <p class="promo-policy" *ngIf="!item.trialPolicy">* Trial not included.</p>
          </div>
          <button class="promo-button button-{{item.active}}">Select this plan</button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<!--
  Template for promo = false && regular checkout
-->
<ng-template #regularCheckout>
  <div class="billing {{cssClasses}}">
    <ng-container *ngFor="let item of planOptions.productItem; let i = index">
      <div class="billing-column {{item.active}}" (click)="onClickFreeTrial(item, i)">
        <div class="billing-chip-container">
          <div class="billing-chip" [ngClass]="{'color-yellow': item.plan == 'Yearly', 'color-blue': item.plan == 'Quarterly'}">
            <span *ngIf="item.plan == 'Yearly' ">Best Value</span>
            <span *ngIf="item.plan == 'Quarterly' ">Most Popular</span>
          </div>
          <div *ngIf="item.percentageCutOff" class="billing-chip billing-w-100 color-black">
            <span>{{item.percentageCutOff}}</span>
          </div>
        </div>
        <div class="billing-content">
          <div>
            <p class="billing-plan">{{item.plan}}</p>
            <div>
              <span class="billing-plan-price">{{item.price}}</span>
              <span class="billing-plan-price-desc">/mo</span>
              <p class="billing-plan-policy">{{item.policy}}</p>
            </div>
          </div>
          <div>
            <button class="billing-button button-{{item.active}}">Get Started Free</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
