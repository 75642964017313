import { Component, OnInit, Inject, ViewChild, ElementRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { States, CountryListAllIsoData } from "./select-options";
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingService, EncryptionService, EventService, SessionService, SitePageService } from 'src/app/services';

import { Subject, of, forkJoin } from 'rxjs';
import { takeUntil, skipWhile, tap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  window = this._document.defaultView;

  @Input() pageType: string;
  @Input() recurlyClient: any;
  @Input() showRegistration: boolean = false;
  @Input() loading: boolean = false;
  @Input() cssClasses;
  @Input() showCheckoutMasterClass;
  @Input() isFairyFunnel: boolean = false;
  @Input() SignUpButtonText: string;
  @Output() submitPurchase = new EventEmitter();

  states = States;
  country = CountryListAllIsoData;

  type: string = '';
  form: FormGroup;
  submitted = false;

  // Payment Information
  paymentItems = {
    apple: {
      init: false,
      acceptedTerms: false,
      enabled: false
    },
    paypal: {
      init: false,
      acceptedTerms: false,
      enabled: true
    },
    creditCard: {
      init: false,
      acceptedTerms: false,
      enabled: true
    },
  }

  displayTerms: { type: string, state: boolean; } = {
    type: '',
    state: false
  };

  paymentInit: boolean = false;
  initStarted: boolean = false;


  productInfo: any;
  isValidCCInfo: boolean = false;
  btToken: string;

  selectedUSCountry: boolean;
  unsubscribe$ = new Subject();

  constructor(@Inject(DOCUMENT) private _document: Document,
    public billingService: BillingService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private encryptionService: EncryptionService,
    private sessionService: SessionService,
    private eventService: EventService,
    private sitePageService: SitePageService
  ) { }

  /** Recurly Integration */
  @ViewChild("recurlyPaymentForm") recurlyPaymentForm: ElementRef;

  recurlyElements;
  recurlyInitilized: boolean = false;
  creditCardInitialize: boolean = false;
  smsOptedIn: boolean = false;
  quizInfo: any;

  @ViewChild("paymentContainer") paymentContainer: ElementRef;

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        fullname: ['', [Validators.required, Validators.pattern(/^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/)]],
        lastname: ['', [Validators.required, Validators.pattern(/^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/)]],
        phone: [''],
        address1: ['', Validators.required],
        address2: [''],
        city: ['', Validators.required],
        country: ['', Validators.required],
        state: ['', Validators.required],
        postalCode: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
        acceptTermsMobile: [false]
      }
    );
    this.onMobileTermsChange();
    this.precheckUserOptedForSMS();
    this.billingService.productInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(plan => this.productInfo = plan);
    this.onCreditChange('credit');
    this.billingService.paymentErrors$.pipe(
      takeUntil(this.unsubscribe$),
      skipWhile(errMsg => !errMsg.length)
    ).subscribe(errors => {
      // Display Toastr Message for each error
      errors.forEach(error => {
        this.toastr.error(error, 'Processing Error', {
          disableTimeOut: true
        });
      });
    });
  }

  ngOnDestroy(): void {
    // unsubscribe from all observables
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngAfterContentInit(): void {
    // this.initializeRecurly();
  }

  ngAfterContentChecked(): void {
    this.checkPaymentFinishInit();
  }

  ngAfterViewInit(): void {
    this.scrollIntoView();
  }

  initializeRecurly() {
    forkJoin([
      this.billingService.applePayEnabled(),
      this.billingService.getRecurlyApiKey()
    ])
      .pipe(
        switchMap(res => {
          let [applePayEnabled, encryptedItem] = res;
          let decryptedString = this.encryptionService.doDecryptString(encryptedItem.message, 5, 44);
          if (!decryptedString)
            return of(null);

          let parsedString = JSON.parse(decryptedString);
          return of({
            crmToken: parsedString.crmToken,
            btToken: parsedString.btToken,
            applePayEnabled: applePayEnabled.message
          });
        }),
        skipWhile(res => !res)
      ).subscribe({
        next: res => {
          let { crmToken, btToken, applePayEnabled } = res;
          this.paymentItems.apple.enabled = applePayEnabled;
          this.btToken = btToken;

          this.recurlyClient.configure(crmToken);
          this.recurlyInitilized = true;

          // Required token was not found.
          // set to initialized so that the checkout does not break
          if (!this.btToken)
            this.updatePaymentInits('apple', this.recurlyInitilized);

          this.updatePaymentInits('paypal', this.recurlyInitilized);
          this.updatePaymentInits('creditCard', this.recurlyInitilized);
        },
        error: error => {
          console.error(error)
        }
      });
  }

  updatePaymentInits(type, state = false) {
    this.paymentItems[type].init = state;
  }

  checkPaymentFinishInit() {
    // Iterate through all payment options and check to see if they have completed their initialization before displaying on page
    let paymentInitArray = Object.keys(this.paymentItems).map(item => this.paymentItems[item].init);
    this.paymentInit = paymentInitArray.every(item => item);
  }

  onFormSubmit(e) {
    this.submitted = true;
    e.preventDefault();
    if (this.form.invalid || !this.isValidCCInfo) {
      return;
    } else {
      // Validate form inputs first before requesting token from payment system
      const form = this.recurlyPaymentForm.nativeElement;
      this.submitPurchase.emit(
        {
          type: 'credit',
          data: { creditCardEl: this.recurlyElements, form: form }
        });
    }
  }

  onCreditChange(e) {
    this.type = e.target?.value ? e.target?.value : e;
    // Initial Recurly elements required for credit card forms
    this.recurlyElements = this.recurlyClient.Elements();
    this.creditCardInitialize = true;
  }

  getPayPalAuth() {
    this.submitPurchase.emit(
      {
        type: 'paypal',
        data: true
      });
  }

  sendApplePayAuth(event) {
    this.submitPurchase.emit(event);
  }

  showPaymentOptions() {
    if (
      !this.recurlyInitilized &&
      !this.initStarted &&
      (this.billingService.showOptions || !this.showRegistration)) {
      this.initStarted = true;
      this.initializeRecurly();
    }
    else if (this.recurlyInitilized) {
      return this.showRegistration ? this.billingService.showOptions : true;
    }
    return false
  }

  countrySelection() {
    // Display either an input or select field based on the country selection
    this.selectedUSCountry = (this.form.get('country').value).toLowerCase() === 'us';
    this.form.get('state').setValue('');
  }

  displayLegalTerms(type) {
    this.displayTerms.type = type;
    this.displayTerms.state = true;
  }

  showMobileTerms() {
    // check if user has already opt-in in Quiz flow:
    if (this.eventService.isUS() && !this.smsOptedIn)
      return true;
    return false;
  }

  onMobileTermsChange(): void {
    this.form.get('acceptTermsMobile').valueChanges.subscribe(value => {
      const phoneControl = this.form.get('phone');
      const validators = [Validators.required];
      if (value) {
        phoneControl.setValidators(validators);
      } else {
        phoneControl.clearValidators();
      }
      phoneControl.updateValueAndValidity();
    });
  }

  precheckUserOptedForSMS() {
    // If user coming  to Sales page form Quiz check if user has already opted in for SMS
    this.quizInfo = window.localStorage.getItem(this.sessionService.quizItemKey);
    if (this.quizInfo && JSON.parse(this.quizInfo)['phone_terms']) {
      this.smsOptedIn = true;
    }
  }

  termsAccepted(type) {
    switch (type) {
      case 'applepay':
        this.paymentItems.apple.acceptedTerms = true;
        break;
      case 'paypal':
        this.paymentItems.paypal.acceptedTerms = true;
        this.getPayPalAuth();
        break;
    }
  }

  resetLegalTerms() {
    // Reset All Various Flags for Terms and Conditions
    for (let item in this.paymentItems) {
      this.paymentItems[item].acceptedTerms = false;
    }

    this.displayTerms = {
      type: '',
      state: false
    }
  }

  checkValidCCInfo(obj) {
    this.isValidCCInfo = Object.keys(obj).every((key) => obj[key].valid);
  }

  scrollIntoView() {
    this.paymentContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  trimSpaces(formControl) {
    formControl.setValue(formControl.value.trim());
  }

  getWWWUrl(url) {
    return this.sitePageService.getWWWUrl(url);
  }
}
