import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BillingService, DomService, SessionService, SitePageService } from 'src/app/services';
import { take } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-payment-redirect-purchase',
  templateUrl: './payment-redirect-purchase.component.html',
  styleUrls: ['./payment-redirect-purchase.component.scss']
})
export class PaymentRedirectPurchaseComponent implements OnInit {
  redtrackID;
  selectedIndex: number;
  pageId: string;
  queryParams;

  loading: boolean = false;

  constructor(
    private sessionService: SessionService,
    private domService: DomService,
    private billingService: BillingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sitePageService: SitePageService
  ) { }


  ngOnInit(): void {
    this.checkPaymentItems();
  }

  checkPaymentItems() {
    // check to see if paypal success parameter is present, if so, send paypal order
    this.activatedRoute.queryParams.pipe(
      take(1),
    ).subscribe(res => {
      this.queryParams = res;
      this.getRedTrackCookie();
      if ('paymentType' in this.queryParams) {
        switch (this.queryParams['paymentType']) {
          case 'paypal':
            if (this.queryParams['paymentState'] === 'success') {
              // Paypal authorization succeeded, process paypal
              this.submitPayPalOrder();
            }
            else if (this.queryParams['paymentState'] === 'cancel') {
              // Payment failed, redirect back to sales page
              this.navigateBySalesPageQueryParams();
            }
            break;
          case 'venmo':
            console.log("venmo");
            break;
          case 'googlepay':
            console.log("googlepay");
            break;
        }
      }
    });
  }

  submitPayPalOrder() {
    let data = {
      token: this.queryParams['token'],
      planKey: this.queryParams['planKey'],
      rtClickId: this.redtrackID,
      pageId: this.queryParams['pageId'],
      weshapeToken: this.queryParams['wtoken']
    }

    this.domService.loadingModalBS.next({ display: true, message: "Processing" });
    this.billingService.submitPayPalOrder(data).pipe(
      finalize(() => this.domService.loadingModalBS.next({ display: false }))
    ).subscribe({
      next: res => {
        if (res && res.nextPageURL) {
          // this.router.navigateByUrl(res.nextPageURL);
          this.sessionService.removeUserItems();
          this.sitePageService.checkAndRedirectUrl(res.nextPageURL);
          return;
        }
      },
      error: error => {
        console.error(error);
        this.loading = false;
        // Append error message to form and focus on message
        this.billingService.paymentErrorsBS.next([error.error.message]);
        this.navigateBySalesPageQueryParams();
      }
    });
  }

  navigateBySalesPageQueryParams() {
    this.router.navigate([this.queryParams['spage']], {queryParams: this.queryParams});
  }

  getRedTrackCookie() {
    this.redtrackID = this.sessionService.getCookie("rtkclickid-store");
  }

}
