<div>
  <ng-template [ngIf]="headerTemplate==1"><app-header-full></app-header-full></ng-template>
  <ng-template [ngIf]="headerTemplate==2"><app-header-min></app-header-min></ng-template>

  <div class="pageColorCss" [hidden]="!showContent">
    <app-timer #appTimer *ngIf="planOptions?.active == 'true' && !externaltimer && showTimer" [main_txt]="'Discounts Ends in'" [container_color]="timerContainerColor" [main_theme_color]="timerMainThemeColor" [main]="'main'" [remainingTime]="planOptions?.ends"></app-timer>
    <div #pageContent (domChanged)="contentLoaded($event)"></div>
    <app-checkout-chips *ngIf="showCheckout" [pageType]="pageType" [planOptions]="planOptions" [selectedIndex]="billingService.selectedIndex" [cssClasses]="cssClasses" (planSelected)="planSelected($event)" ></app-checkout-chips>
    <app-registration-form *ngIf="showCheckout && newUser && billingService.planSelected" [cssClasses]="cssClasses" [isFairyFunnel]="isFairyFunnel" (updateUser)="billingService.showOptions = true"></app-registration-form>
    <app-payment #checkoutEl *ngIf="showCheckout && billingService.planSelected" [isFairyFunnel]="isFairyFunnel" [showCheckoutMasterClass]="showCheckoutMasterClass" [planOptions]="planOptions" [pageType]="pageType" [newUser]="newUser" [pageId]="pageId" [cssClasses]="cssClasses" [SignUpButtonText]="SignUpButtonText"></app-payment>
    <app-feel-better-guarantee *ngIf="showGuarantee"></app-feel-better-guarantee>
    <app-upsell *ngIf="pageType == 'upsell'" [pageId]="pageId" [planOptions]="planOptions"></app-upsell>
  </div>

  <ng-container *ngIf="!showContent">
    <div class="content-loading">
      <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
    </div>
  </ng-container>

  <ng-template [ngIf]="footerTemplate==1"><app-footer></app-footer></ng-template>
  <ng-template [ngIf]="footerTemplate==2"><app-footer-min></app-footer-min></ng-template>
</div>
